import { ComponentType, FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { selectUser } from 'src/slices/userSlice';
import { useAppSelector } from 'src/store/hooks';

const withIsAccountActivated = <P extends any>(
  Component: ComponentType<P>
): FC<P> => (props) => {

    const user = useAppSelector(selectUser);

    if(user?.isActivated) {
      return <Component {...props} />;
    }

    return (
      <Navigate
        to="/home"
        replace
        // state={{redirect: location?.pathname}}
      />
    );
  };

export default withIsAccountActivated;